import React from "react";
import classnames from "classnames";
import * as Ui from "components/ui";
import "./hero.scss"

const Hero = ({title, subTitle, illustration, checkMarks, icons, cta, type, children, withIllustrationBox = true}) => {

    // Type Primary
    if (type === "primary") {
        return (
            <div>
                <Ui.Container>
                  <div className="pt-14 md:pt-20 relative z-10 flex flex-col-reverse md:flex-row items-center justify-center text-dark">
                      <div className="md:w-4/5">
                          <h1 className="font-heading text-4xl md:text-5xl leading-tight font-black">
                              {title}
                          </h1>
                          <p className="text-lg mt-4">{subTitle}</p>
                          {( checkMarks && (<ul className={classnames({'my-5' : true, 'grid grid-cols-3': (checkMarks.length > 3)})}>{
                              checkMarks.map((text, index) => (
                                <li key={index} className="flex items-center text-sm mb-2">
                                  <i className="far fa-check-circle text-green mr-2"></i>
                                  <span className="font-medium">{text}</span>
                                </li>
                              ))
                          }</ul>))}

                          {( icons && (<div className="flex items-center gap-4 my-4 flex-wrap">{
                            icons.map((icon, index) => (
                              <div key={index}>{icon}</div>
                            ))
                          }</div>))}
                          {cta}
                      </div>
                      <div className="hidden md:block relative md:w-2/3 md:h-96 mt-12">
                        <div className="md:absolute md:-mt-30" style={{'left': '50px', 'top': '-100px'}}>
                          <div className={classnames({'shadow-xl rounded-xl': withIllustrationBox})} style={{"width": (!withIllustrationBox) ? '720px': '650px', 'maxWidth': '100%'}}>
                            {illustration}
                          </div>
                        </div>
                      </div>
                  </div>
                  { children }
                  <div className="hidden md:block hero__back stripe-container transform -rotate-3">
                    <div className="stripe-1"></div>
                    <div className="stripe-2"></div>
                  </div>
                </Ui.Container>
          </div>
        )
    } else {
        return (
          <></>
        )
    }
}

export default Hero;