import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import CookieConsent from "react-cookie-consent";
import * as Ui from 'components/ui';
import Footer from './footer';
import Navigation from './navigation/navigation';
import "styles/pages/landing.scss"

const Layout = ({ children, textColor, logoColor }) => {

  const [ optloading, setOptloading ] = useState(true);

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: 'optimize.activate' })
    setTimeout(() => {setOptloading(false)}, 20);
  }, [])

  const bodyClass = 'text-base md:text-xl md:leading-relaxed overflow-x-hidden';

  return (
    <>
      <Helmet>
        <script>
          window.dataLayer = window.dataLayer || [];
        </script>
        <script type="text/javascript" dangerouslySetInnerHTML={{ __html:`
      (function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');
      `}}>
        </script>
        <script async src='https://r.wdfl.co/rw.js' data-rewardful='db7a84' ></script>
        <body className={ (optloading) ? `${bodyClass} --async-hide--` : bodyClass }/>
        <script src="https://kit.fontawesome.com/5bfe31fa0c.js" crossOrigin="anonymous"></script>
      </Helmet>

      <Ui.Container className="z-50">
        <Navigation textColor={textColor} logoColor={logoColor} />
      </Ui.Container>
      {children}

      <CookieConsent
        location="bottom"
        buttonText="Close"
        cookieName="cookie_policy"
        style={{ background: "#dfe2e8", color: '20202D', fontSize: '12px' }}
        buttonStyle={{ backgroundColor: "#20202D", color: "#ffffff", fontSize: "13px", borderRadius: '4px', margin: '10px' }}
        contentStyle={{}}
        expires={150}
      >
        By continuing to use this site you consent to the use of cookies in accordance
        with our <Link to="/cookie-policy/" className="">cookie policy</Link>.
      </CookieConsent>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
