import React from "react";
import * as Ui from "components/ui";

const content = function ({subject}) {
  return {
    preTitle: "Migration in a click",
    title: (<>Migrate your <span className="text-primary">{subject}</span> data</>),
    description: (
      <>
        <p>
          Looking to move your data to {subject} from another provider? <span className="link-highlight">Easily migrate your assets to {subject}</span> from any storage provider.<br/>
          <br/>
          <Ui.FeatureChecks items={['Offline migration', 'Support large storage', 'Support all major providers']} />
        </p>
      </>
    ),
    image: ( <Ui.Image
      className="shadow-2xl rounded-xl"
      filename='simplebackups-app-backup-storage.png'
      alt="Bring your own backup storage"
      style={{maxWidth: 450}}
    />)
  }
};

export default content;