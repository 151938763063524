import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_StorageFeatureControl from "components/content/feature_storage-storage-control.js";
import Content_StorageOptimized from "components/content/feature_storage-optimized.js";
import Content_StorageMigration from "components/content/feature_storage-migration.js";
import StorageProviders from "./_storageProviders";

const Page = ({keyRef, provider}) => (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      title ={`Automated ${provider} backup & migration`}
      description={`Schedule ${provider} storage to any storage replication, cross cloud providers. Supports any S3 compliant storage and Dropbox, Google Drive and more.`}
    />
    <Layout.Hero
      type="primary"
      title={(<>Automated <span className="text-primary">{provider}</span> backup</>)}
      subTitle={(
        <>Automated backup & replication of your {provider} folders to any storage provider.
        </>)}
      checkMarks={[`${provider} data migration`, `${provider} backup`]}
      icons={[
        <p className="mb-2 font-light text-xs tracking-wider uppercase opacity-75">Fully integrated with:</p>,
        <Ui.Image filename={`brands/icons/${keyRef}-ico.png`} alt={`${provider} backup`}  className="w-6"/>,
      ]}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text={`Automate your ${provider} Backup`} urlAtrr={{ sb_source: "website", sb_term: `${keyRef}-hero` }} />)}
      illustration={(
        <Ui.Image className="" filename={`simplebackups-${keyRef}-backup-hero.png`} alt={`SimpleBackups ${provider} backup dashboard: all your backups in one place`}/>
      )}
      withIllustrationBox={false}
    />

    <Ui.Container>
      <Sections.JoinBrandsSection />
    </Ui.Container>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              Content_StorageMigration({subject: provider}),
              Content_StorageFeatureControl({subject: provider}),
              Content_FeatureTrust(),
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt" id="features">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">Want to geek out on the technical details?</Ui.Typography>
          <div className="text-center">
            <p className="mb-6">SimpleBackups is fully integrated with {provider}, meaning all is configurable from an easy-to-use UI.</p>
            <p className="py-5 text-lg font-bold">Automate your {provider} backups or just migrate your data to another provider!</p>
            <ul className="flex flex-wrap gap-x-8 gap-y-4 justify-center text-sm font-medium">
              <li><i className="far fa-check text-green mr-2"></i>Incremental Backup</li>
              <li><i className="far fa-check text-green mr-2"></i>Long background process support</li>
              <li><i className="far fa-check text-green mr-2"></i>Fail-over detection</li>
              <li><i className="far fa-check text-green mr-2"></i>Secured transfer</li>
              <li><i className="far fa-check text-green mr-2"></i>Storage SSE-C support</li>
            </ul>

            <div className="mt-16 mb-8">
              <Ui.Typography tag="h3" className="text-2xl text-center ">Wait... there's even more...</Ui.Typography>
            </div>
            <Sections.FeatureSlider tags={["snapshot"]} />
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt" id="features">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">Supported Providers</Ui.Typography>
          <p className="mb-6 text-center">Effortlessly connect your SimpleBackups account with your favorite storage providers. Select you bucket, your destination storage and start protecting your data.</p>
          <div className="mt-16 text-center">
            <StorageProviders></StorageProviders>
            <div className="mt-16"><Ui.Link arrow="right" to="/catalog/">Discover our Integration catalog</Ui.Link></div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionLearn
      title={<>Wanna learn more about {provider} backup?</>}
      text={`Find out more and get started with our ${provider}  Backup guides`}
      tags={['storage-replication']}
    />

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended />
    <Sections.SectionFaq color="alt" faqs={['what_storage_replication_service', 'use_for_client_2', 'use_for_other_things']}/>

  </Layout.Layout>
)

export default Page
