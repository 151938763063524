import React from "react";
import * as Ui from "components/ui";
import "./hero.scss"

const Hero = () => {
    return (
        <div>
            <Ui.Container>
              <div className="hidden md:block hero__back hero__back--sm stripe-container transform -rotate-3">
                <div className="stripe-2"></div>
              </div>
            </Ui.Container>
      </div>
    )
}

export default Hero;