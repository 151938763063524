import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import filter from "lodash/filter";
import get from "lodash/get";
import { JSONLD, Generic } from "react-structured-data";

const defaultStructuredData = () => (
  <JSONLD dangerouslyExposeHtml={true}>
    <Generic
      type="WebApplication"
      jsonldtype="WebApplication"
      schema={{
        applicationCategory: "Backup solution",
        operatingSystem: "all",
        url: "https://simplebackups.com",
        brand: "SimpleBackups",
        name: "SimpleBackups",
        description:
          "Automate backups for all your servers, websites, databases (MySQL, PostgreSQL, MongoDB, Redis) and storage. Connect any provider, quick recovery, and peace of mind.",
        /*"aggregateRating": {
        "@type": "aggregateRating",
        "ratingValue": "4.8",
        "ratingCount": "568",
      }*/
      }}
    />
  </JSONLD>
);

function SEO(props) {
  let {
    description,
    author,
    lang,
    meta,
    title,
    refKey,
    image,
    date,
    lastUpdateDate,
    uri,
  } = props;
  const { site, allJavascriptFrontmatter } = useStaticQuery(
    graphql`
      query {
        allJavascriptFrontmatter {
          edges {
            node {
              frontmatter {
                pageMetaData {
                  description
                  author
                  refKey
                  title
                }
              }
              id
            }
          }
        }
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const pageMetaData = filter(allJavascriptFrontmatter.edges, (o) => {
    const nodeKey = get(o, "node.frontmatter.pageMetaData.refKey");
    return refKey === nodeKey ? true : false;
  });

  const metaData =
    pageMetaData.length > 0
      ? pageMetaData[0].node.frontmatter.pageMetaData
      : site.siteMetadata;
  const metaAuthor = author || metaData["author"] || "simplebackups.com";
  const metaDescription = description || metaData.description;
  const metaTitle = title || metaData.title;
  const metaImage =
    "https://simplebackups.com" + (image || "/images/share-image.png");
  const metaUrl = "https://simplebackups.com" + uri;
  const iso8601dateFormatter = (date) => {
    return date;
  };

  console.log(metaData);

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={metaTitle}
        titleTemplate={`%s`}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: props.uri ? "article" : "website",
          },
          {
            property: `og:image`,
            content: metaImage,
          },
          {
            property: `og:url`,
            content: metaUrl,
          },
          {
            name: `og:author`,
            content: metaAuthor,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: metaAuthor,
          },
          {
            name: `twitter:title`,
            content: metaTitle,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            itemprop: "datePublished",
            content: iso8601dateFormatter(date),
          },
          lastUpdateDate
            ? {
                itemprop: "dateModified",
                content: iso8601dateFormatter(lastUpdateDate),
              }
            : {},
          {
            name: "article:published_time",
            content: iso8601dateFormatter(date),
          },
          lastUpdateDate
            ? {
                name: "article:modified_time",
                content: iso8601dateFormatter(date),
              }
            : {},
        ].concat(meta)}
      />
      {defaultStructuredData()}
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
      (function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');
      `,
        }}
      ></script>
      <script
        async
        src="https://r.wdfl.co/rw.js"
        data-rewardful="db7a84"
      ></script>
    </>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.string,
  structuredData: PropTypes.any,
};

export default SEO;
