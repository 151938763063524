import React from "react";
import * as Comp from 'components/index';
import * as Ui from "components/ui";
import Image from "../image";

const content = function (props = null) {
  let subject = (props && props.subject) ? props.subject : 'backups'
  let keyRef = (props && props.keyRef) ? props.keyRef : 'default'
 return   {
   preTitle: "Trust your backups",
   title: (<>Trust your {subject} are running <span className="text-primary">securely</span></>),
   description: (
     <>
       <p>
         <span className="link-highlight">Security and privacy are at the core of what we do!</span><br/>Benefit from the strict European requirements on all your backups, no matter where you’re based.
         <Ui.FeatureChecks items={['Team & User Authorization', 'Multiple-Factor Auth', 'Security Audited', 'SSL & self-hosted keys']} />

         <div className="flex my-6">
           <div className=" w-32 ">
             <Image filename="gdpr-compliant"></Image>
           </div>
           <div className="w-32 ">
             <Image filename="iso27001-horizontal.png"></Image>
           </div>
         </div>

         <Ui.Link arrow="right" to="/security-first/">How we treat your data</Ui.Link>

       </p>
     </>
   ),
   image: ( <Comp.Image
     className=""
     filename={`simplebackups-${keyRef}-backup-security-settings.png`}
     alt={`Backup ${subject} security`}
     style={{maxWidth: 550}}
   />)
 };
}

export default content;