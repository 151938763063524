import React from "react";
import * as Ui from "components/ui";

const content = function ({subject}) {
  return {
    preTitle: "Optimize your storage backup",
    title: (<>Automated <span className="text-primary">Storage to Storage</span> backup</>),
    description: (
      <>
        <p>
          Storage replication and backup between providers, via a simple and controllable interface.<br/>
          Enjoy all the integrations, tools, monitoring systems, to make it a breeze.
          Be notified, when something goes wrong, give access to your team or customers, and more.
          <br/>
          <Ui.FeatureChecks items={['Flexible Scheduling', 'Advanced notifications', 'Incremental sync', 'Fail-over detection']} />
          <Ui.Link arrow="right" to="/features/">View all features</Ui.Link>
        </p>
      </>
    ),
    image: ( <Ui.Image
      className="shadow-2xl rounded-xl"
      filename='simplebackups-app-backup-expert.png'
      alt="Optimized Storage to storate backup"
      style={{maxWidth: 450}}
    />)
  }
};

export default content;